import React from 'react'
import "./Footer.css"
import linkedin from "../../assets/linkedin.png"
import instagram from "../../assets/instagram.png"
import facebook from "../../assets/facebook.png"

const Footer = () => {
  return (
    <div className="f-container">
        <hr/>
    <div className="footer">
        <div className="logos">
            <img src={instagram} alt="" />
            <img src={facebook} alt="" />
            <img src={linkedin} alt="" />
        </div>
        <div className="c-logo">
           cricketclub@2023. All rights reserved
        </div>
    </div>
    <div className="blur f-blur-1"></div>
    <div className="blur f-blur-2"></div>
    </div>
  )
}

export default Footer