import React from 'react'
import "./Plans.css"
import { plansData } from '../../data/PlansData'
import whiteTick from "../../assets/white-tick.png"

const Plans = () => {
  return (
    <div className="plans-container" id='plans'>
      <div className="blur plan-blur-1"></div>
      <div className="blur plan-blur-2"></div>
      <div className="programs-header">
        <span className='stroke-text'>READY TO START</span>
        <span>YOUR JOURNEY</span>
        <span className='stroke-text'>NOW WITH US</span>
      </div>

      <div className="plans">
        {plansData.map((plan, i)=>(
           <div className="plan" key={i}>
            <div>{plan.icon}</div>
            <span>{plan.name}</span>
            <span>$ {plan.price}</span>
            <div className="features">
              {plan.features.map((feature,i)=> (
                <div className="feature">
                  <img src={whiteTick} alt=''/>
                  <span key={i}>{feature}</span>
                </div>
              ))}
            </div>
            <div>
              <span>See more benefits</span>
            </div>
            <button className='btn'>Join Now</button>
           </div>
        ))}
      </div>
    </div>
  )
}

export default Plans