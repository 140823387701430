import React, { useState } from 'react'
import logo from "../../assets/ccc.png"
import menu from "../../assets/menu.png"
import {Link} from "react-scroll"
import "./Header.css"

const Header = () => {
  const mobile = window.innerWidth <= 768 ? true:false;
  const [menuOpened, setMenuOpened] = useState(false)
  return (
    <div className='header'>
        <img src={logo}  className='logo'/>
        {(menuOpened === false && mobile === true) ? <div onClick={()=>setMenuOpened(true)} ><img className='menu' src={menu} alt=''/></div>:
        <ul className="header-menu">
        <li >
        <Link
             to='home'
             span={true}
             smooth={true}
             onClick={()=>setMenuOpened(false)}
          >Home</Link>
        </li>
        <li >
        <Link
             to='training'
             span={true}
             smooth={true}
             onClick={()=>setMenuOpened(false)}
          >Trainings</Link>
        </li>
        <li>
        <Link
             to='reasons'
             span={true}
             smooth={true}
             onClick={()=>setMenuOpened(false)}
          >Why Us</Link>
        </li>
        <li >
        <Link
             to='plans'
             span={true}
             smooth={true}
             onClick={()=>setMenuOpened(false)}
          >Plans</Link>
        </li>
        <li >
          <Link
             to='testimonials'
             span={true}
             smooth={true}
             onClick={()=>setMenuOpened(false)}
          >Testimonials</Link></li>
    </ul> }
        {/* <ul className="header-menu">
            <li>Home</li>
            <li>Trainings</li>
            <li>Why us</li>
            <li>Plans</li>
            <li>Testimonials</li>
        </ul> */}
    </div>
  )
}

export default Header