import React from 'react';
import './Trainings.css';
import {trainingsData} from "../../data/TrainingsData"
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

export const Trainings = () => {
  return (
    <div className="training" id="training">
        <div className="training-header">
            <span className='stroke-text'>Explore our</span>
            <span>Training</span>
            <span className='stroke-text'>to shape you</span>
        </div>

        <div className="training-section">
            {trainingsData.map((program)=>{
                return (
                    <div className="category">
                    {program.image}
                    <span>{program.heading}</span>
                    <span>{program.details }</span>
                    <div className="join-now">
                        <span>Join Now </span>
                        <ArrowForwardOutlinedIcon className='arrowIcon'/>
                    </div>
                </div>
                )
            })}
        </div>
    </div>
  )
}
