import React from 'react'
import "./Hero.css"
import Header from '../Header/Header'
import cricket from "../../assets/cricket-player.png"
import cricketBall from "../../assets/orange-ball.png"
import {motion} from "framer-motion"
import NumberCounter from "number-counter"

const Hero = () => {
  const transition = {type: "spring", duration: 3}
  const mobile = window.innerWidth<=768 ? true : false;
  return (
    <div className="hero" id='home'>
      <div className="blur hero-blur"></div>
      <div className="left-h">
        <Header />
        <div className="adv">
          <motion.div
           initial={{left: mobile ? "125px" : "175px"}}
           whileInView={{left:"8px"}}
           transition={{...transition, type:"tween"}}
          >

          </motion.div>
          <span>The best cricket club in Town</span>

        </div>
        <div className="heading">
          <div>
            <span className='stroke-text'>Test</span>
            <span> your limit.</span>
          </div>
          <div>
            {/* <span>challenge your mind.</span> */}
            <span>win the battle.</span>
            {/* <span className='stroke-text'>battle.</span> */}

          </div>
          <div>
            {/* <span>Test your limit. challenge your mind. win the battle.</span> */}
          </div>
        </div>
        <div className='stats'>
          <div>
            <span><NumberCounter end={100}  start={67} delay="4" preFix="+"/></span>
            <span>expert coaches</span>
          </div>
          <div>
            <span><NumberCounter end={400}  start={299} delay="3" preFix="+"/></span>
            <span>players joined</span>
          </div>
          <div>
            <span><NumberCounter end={25}  start={5} delay="4" preFix="+"/></span>
            <span>training sessions</span>
          </div>
        </div>
        {/* buttons */}
        <div className="button">
          <buttons className="btn">Get Started</buttons>
          <buttons className="btn">Learn More</buttons>
        </div>
      </div>
      <div className="right-h">
        <button className="btn">Join Now</button>

        <img src={cricket} alt="" className="cricket-player" />
        <motion.img
          initial={{right:"11rem"}}
          whileInView={{right:"40rem"}}
          transition={transition}
         src={cricketBall} alt="" className="cricket-ball" />
      </div>
    </div>
  )
}

export default Hero