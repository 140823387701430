import batting from "../assets/batting.png";
import bowling from "../assets/bowling.png";


export const trainingsData = [
    {
        image: (
            <img src={batting} alt="" style={{height:"6rem", width:"4rem"}}/>
        ),
        heading: "Batting Training",
        details: "Whether you're a beginner or an experienced player, the right training can help you improve your technique, footwork, timing, and shot selection."
    },
    {
        image: (
            <img src={batting} alt="" style={{height:"6rem", width:"4rem"}}/>
        ),
        heading: "Bowling Training",
        details: "The training may include drills for developing your grip, improving your run-up, enhancing your release, and practicing different types of deliveries."
    },
    {
        image: (
            <img src={batting} alt="" style={{height:"6rem", width:"4rem"}}/>
        ),
        heading: "Batting Training",
        details: "Whether you're a beginner or an experienced player, the right training can help you improve your technique, footwork, timing, and shot selection."
    },
    {
        image: (
            <img src={batting} alt="" style={{height:"6rem", width:"4rem"}}/>
        ),
        heading: "Bowling Training",
        details: "The training may include drills for developing your grip, improving your run-up, enhancing your release, and practicing different types of deliveries."
    }
]

