import free from "../assets/free.png"
import premium from "../assets/premium.png"
import pro from "../assets/pro.png"

export const plansData = [
    {
        icon: (
            <img src={free} style={{height:"3rem", width:'3rem'}} alt=""/>
        ),
        name: "BASIC PLAN",
        price: "25",
        features: [
            "2 hours of training",
            "Free consultation from coaches",
            "Acces to the night practice"
        ]
    },
    {
        icon: (
            <img src={premium} style={{height:"3rem", width:'3rem'}}  alt=""/>
        ),
        name: "PREMIUM PLAN",
        price: "30",
        features: [
            "5 hours of training",
            "Free consultation from coaches",
            "Acces to the night practice"
        ]
    },
    {
        icon: (
            <img src={pro} style={{height:"3rem", width:'3rem'}} alt=""/>
        ),
        name: "PRO PLAN",
        price: "45",
        features: [
            "8 hours of training",
            "Consultation of private coache",
            "Acces to the GYM facilities"
        ]
    },
]