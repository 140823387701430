import image1 from "../assets/person1.jpg"
import image2 from "../assets/person2.jpg"
import image3 from "../assets/person3.jpg"
import sachin from "../assets/Sachin-Tendulkar.jpg"
import smrithi from "../assets/smthi.jpg"
import ravi from "../assets/ravi.jpg"



export const testimonialsData = [
    {
        image:sachin,
        review: "I recently had the pleasure of joining a local cricket coaching club, and I must say, I was thoroughly impressed with the experience.",
        name: "Sachin Tendulkar",
        status: "Former indian team player"
    },
    {
        image:ravi,
        review: "I would highly recommend this cricket coaching club to anyone looking to learn the game or take their skills to the next level.",
        name: "Ravi Shasthri",
        status: "Former Indian Coach"
    },
    {
        image:smrithi,
        review: " Whether you were a beginner just starting out or a seasoned veteran looking to fine-tune your skills, they had the expertise to help you improve.",
        name: "Smrithi Mandhana",
        status: "Womens team Captain"
    },
]