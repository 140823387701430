import React from 'react'
import "./Reasons.css"
import cricket2 from "../../assets/cricket-2.jpg"
import cricket1 from "../../assets/cricket-1.jpg"

import cricket7 from "../../assets/cricket-7.jpg"
import cricket4 from "../../assets/cricket-4.jpg"
import cricket6 from "../../assets/cricket-6.jpg"
import adidas from "../../assets/adidas-logo.png"
import nike from "../../assets/nike-logo.png"
import puma from "../../assets/puma-logo.png"
import newbalance from "../../assets/nb-logo.png"
import LabelImportantOutlinedIcon from '@mui/icons-material/LabelImportantOutlined';
import pic1 from "../../assets/train1.jpg"
import pic2 from "../../assets/train2.jpg"






const Reasons = () => {
  return (
    <div className="reasons" id='reasons'>
        <div className="left-r">
            <img src={cricket2} alt="" />
            <img src={pic2} alt="" />
            <img src={cricket4} alt="" />
            <img src={cricket6} alt="" />
        </div>
        <div className="right-r">
            <span>some reasons</span>
            <div className="why">
                <span className='stroke-text'>why </span>
                <span>choose us?</span>
            </div>
            <div className="details-r">
                <div>
                    <LabelImportantOutlinedIcon className='IconStyle'/>
                    <span>OVER 50+ EXPERT COACHES</span>
                </div>
                <div>
                    <LabelImportantOutlinedIcon className='IconStyle'/> 
                    <span>TRAIN FASTER AND SMARTER THAN BEFORE</span>
                </div>
                <div>
                    <LabelImportantOutlinedIcon className='IconStyle'/>
                    <span>1 FREE TRAINING SESSION FOR NEW MEMBERS</span>
                </div>
                <div>
                    <LabelImportantOutlinedIcon className='IconStyle'/>
                    <span>RELIABLE PARTNERS</span>
                </div>
            </div>
            <span style={{color: "var(--gray)",fontWeight: "normal"}}>OUR PARTNERS</span>
            <div className='partners'>
            <img src={adidas} alt=''></img>
            <img src={newbalance} alt=''></img>
            <img src={nike} alt=''></img>
            </div>

        </div>
    </div>
  )
}

export default Reasons